import deLocale from 'element-plus/lib/locale/lang/de';

const de = {
  ...deLocale,
  /////////////////// 基本通用 /////////////////
  common: {
    add: "Hinzufügen",
    delete: "Löschen",
    update: "Aktualisieren",
    confirm: "Bestätigen",
    cancel: "Abbrechen",
    query: 'Abfragen',
    empty: 'Entleeren',
    copy: 'Copy',
    tipContent: "Tipp-Inhalt",
    success: "Erfolgreich!",
    unknowErr: 'Anforderung fehlgeschlagen!',
    pleaseInput: "Bitte eingeben",
    noEmpty: "Darf nicht leer sein",
    none: "Nein",
    have: 'Ja',
    disable: 'Deaktivieren',
    enable: 'Enable',
    normal: 'Normal',
    expired: 'Abgelaufen',
    confirmDelete: "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
    confirmAdd: "Sind Sie sicher, dass Sie dieses Element hinzufügen möchten?",
    confirmEditPsw: 'Sind Sie sicher, dass der Benutzer das Passwort ändert?',
    confirmResetPsw: 'Sind Sie sicher, dass der Benutzer das Passwort zurücksetzt?',
    confirmEditStatus: 'Sind Sie sicher, dass der Benutzerstatus geändert wird?',
    copyText: "Kopieren erfolgreich",
    updateSuccess: 'Aktualisierung erfolgreich!',
    updateFailt: 'Aktualisierung fehlgeschlagen!',
    all: 'Alle',
    download: 'Herunterladen',
    downloadTemplate: 'Download Template',
    pleaseSelect: 'Bitte auswählen',
    default: 'Standard',
    day: 'Tag',
    hour: 'Stunden',
    month: 'Monat',
    week: 'Woche',
    oneHour: '1 Stunde',
    Hour24: '24 Stunden',
    day7: '7 Tage',
    day30: '30 Tage',
    year10: '10 Jahre',
    exportData: 'Daten exportieren',
    multipleExportData: 'Daten stapelweise exportieren',
    uploadExcelWarn: 'Please upload an Excel file',
    time: 'Zeit',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    data: 'Daten',
    yes: 'Ja',
    no: 'Nein',
    selectMonth: 'Monat auswählen',
    selectYear: 'Jahr auswählen',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'März',
    Apr: 'Apr',
    May: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sept: 'Sept',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dez',
    nearly10: 'Letzte 10',
    s: 's',
    ms: 'ms',
    min: 'Min',
    timeZone: 'Time Zone',
    chinese: 'Chinesisch',
    english: 'Englisch',
    polish: 'Polnisch',
    german: 'Deutsch',
    dispatchWarn: 'The command is issued too frequently. Please try again in 3 seconds',
    filePicWarn: 'The file type must be one of jpg or png',
    picSizeWarn: 'The image size cannot exceed 500KB',
    top: 'Top',
    custom: 'Custom',
    condition: 'Condition',
    greater: 'Greater than',
    greatEqual: 'Greater than or equal to',
    equal: 'Equal to',
    less: 'Less than',
    lessEqual: 'Less than or equal to',
    contains: 'Contains',
  },
  
  /////////////////// 按钮文案 /////////////////
  buttonText: {
    add: "Hinzufügen",
    save: "Speichern",
    reset: "Zurücksetzen",
    delete: "Löschen",
    edit: "Bearbeiten",
    set: 'Einstellen',
    detail: 'Details',
    addProduct: 'Produkt hinzufügen',
    search: 'Suchen',
    loadMore: 'Mehr laden',
    position: 'Position',
    toggleStatus: 'Status umschalten',
    syncDev: "Geräteinformationen synchronisieren",
  },

  /////////////////// 表单文案 /////////////////
  formText: {
    operation: 'Operation',
    parentCorporation: 'Muttergesellschaft',
    corporation: 'Unternehmen',
    PlatformName: 'Plattform',
    address: 'Adresse',
    remake: 'Bemerkung',
    email: 'E-Mail',
    createTime: 'Erstellungszeit',
    updateTime: 'Aktualisierungszeit',
    expirationTime: 'Ablaufdatum',
    resetTime: 'Zurücksetzen-Zeit',
    resetBy: 'Zurücksetzen-Person',
    lang: 'Lokale Sprache',
    lastLoginTime: 'Letzte Anmeldezeit',
    memberName: 'Mitgliedsname',
    username: 'Benutzername',
    cropAdmin: 'Unternehmensadministrator',
    corpInstaller: 'Corporation installer',
    name: 'Name',
    status: 'Status',
    phone: 'Kontaktinformationen',
    mPhone: 'Phone',
    controlRight: 'Gerätesteuerungsrecht',
    success: 'Erfolg',
    fail: 'Fehler',
    latAndlon: '(Breitengrad, Längengrad)',
    showIcon: 'Symbol anzeigen',
    showClientName: 'Benutzerdefinierten Plattformnamen anzeigen',
    miniAdmin :'Mini-Programm-Verwaltungsberechtigungen',

    // 产品
    product: 'Produkt',
    productName: 'Produktname',
    productId: 'Produkt-ID',
    productType: 'Produkttyp',
    platformType: 'Plattformtyp',
    readOnly: 'Nur lesen',
    writeOnly: 'Nur Schreiben',
    writeRead: 'Lesen und Schreiben',
    devAudit: 'Device Review',

    // 设备
    deviceOnline: 'Gerät online',
    deviceOffline: 'Gerät offline',
    deviceUnactive: 'Gerät inaktiv',
    deviceDisable: 'Gerät deaktivieren',
    deviceUnauthorized: 'Gerät ist nicht autorisiert',
    deviceNameOrRemake: 'Gerätename/Bemerkungsname',
    remakeName: 'Bemerkungsname',
    deviceId: 'Geräte-ID',
    deviceName: 'Gerätename',
    deviceStatus: 'Gerätestatus',

    numberType: 'Nummerntyp',
    min: 'Minimalwert',
    max: 'Maximalwert',
    step: 'Schrittweite',
    multiple: 'Vielfach',
    unit: 'Einheit',
    unitName: 'Name der Einheit',
    enumType: 'Aufzählungstyp',
    enumItem: 'Aufzählungselement',
    param: 'Parameter',
    paramName: 'Parametername',
    paramUnit: 'Parametereinheit',
    paramDescription: 'Parameterbeschreibung',
    booleanType: 'Boolescher Typ',
    faultType: 'Fehlertyp',
    faultItem: 'Fehlerelement',
    valueRange: 'Wertebereich',
    stringType: 'Zeichentyp',
    
    // sn
    snCode: 'SN-Code',
    noDevExists: 'Kein Gerät vorhanden',
    devExists: 'Gerät vorhanden',
    devAdded: 'Gerät hinzugefügt',
    scanTime: 'Scanzeit',

    // wx授权码
    used: 'Verwendet',
    notUsed: 'Nicht verwendet',
    authCode: 'Autorisierungscode',
    corporationId: 'Firmen-ID',
    creatorId: 'Erstellungs-ID',
    registerId: 'Registrierungs-ID',
  },

  /////////////////// 校验规则文案 /////////////////
  rulesText: {
    corporationNoEmpty: 'Die Firma darf nicht leer sein',
    clientNameNoEmpty: 'Der Plattformname darf nicht leer sein',
    addressNoEmpty: 'Die Adresse darf nicht leer sein',
    memberNameNoEmpty: 'Der Mitgliedsname darf nicht leer sein',
    productNoEmpty: 'Das Produkt darf nicht leer sein',
    nameNoEmpty: 'Der Name darf nicht leer sein',
    newPswNoEmpty: 'Das neue Passwort darf nicht leer sein',
    passwordNoEmpty: 'Das Passwort darf nicht leer sein',
    agiainPswNoEmpty: 'Bitte geben Sie das Bestätigungspasswort ein',
    samePswNoEmpty: 'Die beiden Passwörter sind inkonsistent. Bitte geben Sie sie erneut ein',
    usernameNoEmpty: 'Der Benutzername darf nicht leer sein',
    childAccoutCountNoEmpty: 'Die Gesamtzahl der Unterkonten darf nicht leer sein',
    roleNameNoEmpty: 'Der Rollenname darf nicht leer sein',
    rightCodeNoEmpty: 'Der Berechtigungscode darf nicht leer sein',
    orderNoEmpty: 'Die Reihenfolge darf nicht leer sein',
    logTitleNoEmpty: 'Der Protokolltitel darf nicht leer sein',
    logContentNoEmpty: 'Der Protokollinhalt darf nicht leer sein',
    placeEnterValue: 'Bitte geben Sie den Inhalt ein, den Sie ändern möchten',
    placeEnterDeviceName: 'Geben Sie den Gerätenamen oder die Geräte-ID für die Abfrage ein',
    publicIdentifyNoEMpty: 'Der öffentliche Datenpunkt darf nicht leer sein',
    dataPointNoEmpty: 'Datenpunkte dürfen nicht leer sein',
    dataPointNameNoEmpty: 'Der Datenpunktname darf nicht leer sein',
    modelNoEmpty: 'Der Modellwert darf nicht null sein',
    timeOpenPointHourNoEmpty: 'Datenpunkte geplanter Einschaltung (Stunde) darf nicht null sein',
    timeOpenPointMinNoEmpty: 'Datenpunkte geplanter Einschaltung (Minuten) darf nicht null sein',
    timeClosePointHourNoEmpty: 'Datenpunkte geplanter Ausschaltung (Stunde) darf nicht null sein',
    timeClosePointMinNoEmpty: 'Datenpunkte geplanter Ausschaltung (Minuten) darf nicht null sein',
    enginnerAddressNoEmpty: "Die Projekt-Adresse darf nicht leer sein",
    engineerNameNoEmpty: 'Der Projekt-Name darf nicht leer sein',
    engineerTimeRangeNoEmpty: 'Die Start- und Endzeit des Projekts darf nicht leer sein',
    engineerStatusNoEmpty: 'Der Projekt-Status darf nicht leer sein',
    selectAddDevice: 'Wählen Sie das Gerät aus, das Sie hinzufügen möchten',
    productIdNoEmpty: 'Die Produkt-ID darf nicht leer sein',
    productNameNoEmpty: 'Der Produktname darf nicht leer sein',
    productTypeNoEmpty: 'Der Produkttyp darf nicht leer sein',
    platformTypeNoEmpty: 'Der Plattformtyp darf nicht leer sein',
    readWriteTypeNoEmpty: 'Der Lese-/Schreibtyp darf nicht leer sein',
    belongProductNoEmpty: 'Das Produkt darf nicht leer sein',
    identifierNoEmpty: 'Der Identifikator darf nicht leer sein',
    snNoEmpty: 'Die Sequenznummer darf nicht leer sein',
    dataTypeNoEmpty: 'Der Datentyp darf nicht null sein',
    minNoEmpty: 'Der Mindestwert darf nicht leer sein',
    maxNoEmpty: 'Der Höchstwert darf nicht leer sein',
    stepNoEmpty: 'Die Schrittweite darf nicht leer sein',
    multipleNoEmpty: 'Die Zahl des Vielfachen darf nicht leer sein',
    paramDescriptNoEmpty: 'Die Parameterbeschreibung darf nicht leer sein',
    paramNoEmpty: 'Der Parameter darf nicht null sein',
    paramValueNoEmpty: 'Der Parameterwert darf nicht leer sein',
    paramNameNoEmpty: 'Der Parametername darf nicht leer sein',
    identifierNameNoEmpty: 'Der Identifikator darf nicht leer sein',
    usernameNoExist: 'Der Benutzername existiert nicht',
    enterAnInteger: 'Bitte geben Sie eine Ganzzahl ein',
    enterValidNumber: 'Bitte geben Sie eine gültige Zahl ein',
    enterInt1to50: 'Die Zahl muss größer oder gleich 1 und kleiner oder gleich 50 sein',
    devIdNoEmpty: 'Die Geräte-ID darf nicht leer sein',
  },

  /////////////////// 登录页面 /////////////////
  login: {
    chicoPlatformName: "IOT-Plattform",
    username: "Benutzername",
    password: "Passwort",
    remberpass: "Passwort merken",
    forgetpass: "Passwort vergessen?",
    login: "Anmelden",
    fillusername:"Benutzername darf nicht null sein",
    fillpassword:"Das Passwort darf nicht null sein",
    passNoSpaces:"Das Passwort darf keine Leerzeichen enthalten",
    passLengthMoreThan8:"Das Passwort darf nicht weniger als 8 Ziffern haben",
    passLengthMoreThan16:"Das Passwort darf nicht mehr als 16 Ziffern haben",
    userLengthLessThan4: 'Benutzername darf nicht weniger als 4 Zeichen haben',
    userLengthMoreThan10: 'Benutzername darf nicht mehr als 10 Zeichen haben',
    noSpecialSymbols: "Darf nur alphanumerische Zeichen und englische Symbole enthalten",
    noAccess: "Kein Zugriff",
    loginDate:"Anmeldung abgelaufen",
    errAdmin:"Anforderung fehlgeschlagen. Bitte wenden Sie sich an den Administrator",
    pleaseLogin: "Bitte melden Sie sich zuerst an!",
    loginAgain: "Bitte melden Sie sich nicht wiederholt an!",
    errOldPass:"Das alte Passwort ist falsch",
    fillconfirmpass:"Passwörter sind inkonsistent",
    fillOldPass:"Das alte Passwort darf nicht null sein",
    fillNewPass:"Das neue Passwort darf nicht null sein",
    oldPass:"Altes Passwort",
    newPass:"Neues Passwort",
    confirmNewPass:"Neues Passwort bestätigen",
    editPasswordTitle: "Passwort ändern",
    oldPasswordPlaceholder: "Bitte geben Sie das alte Passwort ein",
    newPasswordPlaceholder: "Bitte geben Sie das neue Passwort ein",
    comfirlPasswordPlaceholder: "Bitte bestätigen Sie das neue Passwort",
    loginremberpass: 'Passwort merken',
    name: 'Name',
    loginSuccess: 'Anmeldung erfolgreich',
    loginOutSuccess: 'Erfolgreich abgemeldet',
  },

  /////////////////// 注册页面 /////////////////
  register: {
    customPlatformName: "Custom Platform Name",
    email: "Registration Email",
    verifiCode: "Verification Code",
    getVerifiCode: "Obtain verification code",
    register: "Register",
    registerSuccess: "Registered successfully",
    logTips: "You can log in to the IoT management platform",
    goLogin: "Go to login",
    linkInvaild: "Registration Link Invalid",
    linkTips: "Please contact the administrator to obtain the link again",
    errorEmail: "Please enter the correct email address",
    errorPhone: "Please enter the correct phone number",
    registerTitle: "Registration of IoT Management Platform",
  },

  /////////////////// 头部组件 /////////////////
  header: {
    personCenter: "Persönliches Zentrum",
    support:"Technischer Support",
    updateLog:"Update-Protokoll",
    editPassword: "Passwort bearbeiten",
    loginOut: "Abmelden",
  },

  ///////////////////   权限   /////////////////
  /////////////////// 菜单列表 /////////////////
  menuList: {
    menuManage: "Menüliste verwalten",
    addMenu: "Menü hinzufügen",
    editMenu: "Menü bearbeiten",
    chooseMenuEdit: "Zum Bearbeiten ein Element aus der linken Menüliste auswählen",
    confirmDeleteMenu: "Sind Sie sicher, dieses Menü zu löschen?",
    menuName: "Menüname",
    englishName: "Englischer Name",
    polishName: "Polnischer Name",
    germanName: "Deutscher Name",
    higherMenu: "Vorheriges Menü",
    pageUrl: "Seitenlink",
    rankPosition: "Rangposition",
    icon: "Symbolname",
    menuList: 'Menüliste',
  },
  /////////////////// 角色权限 /////////////////
  roleAuthorization: {
    roleRightManage: "Rollenrechteverwaltung",
    addRole: "Rolle hinzufügen",
    distributionMenu: "Verteilung des Menüs",
    confirmPermission: "Berechtigung bestätigen",
    selectRoleHasMenu: "Wählen Sie ein Element aus der Rollenliste auf der linken Seite aus, um die der Rolle entsprechenden Menürechte anzuzeigen.",
  },
  /////////////////// 公司/组织 /////////////////
  corporation: {
    corporationList: 'Unternehmensliste',
    corporationManage: 'Unternehmensverwaltung',
    corporationName: 'Unternehmensname',
    addCorporation: 'Unternehmen hinzufügen',
    addUserCorporation: 'Händler hinzufügen',
    deleteDealer: 'Delete Dealer',
    editCorporation: 'Unternehmen bearbeiten',
    backCorporationList: 'Zurück zur Unternehmensliste',
    memberInfo: 'Mitgliedsinformationen',
    addMember: 'Mitglied hinzufügen',
    editMember: 'Mitglied bearbeiten',
    productInfo: 'Produktinformationen',
    addProduct: 'Produkt hinzufügen',
    devInfo: 'Geräteinformationen',
    snInfo: 'SN-Code-Informationen',
    wxAuthCodeInfo: 'Wechat-Autorisierungscode-Informationen',
    addAuthCode: 'Autorisierungscode hinzufügen',
    batchAddAuthCode: 'Autorisierungscode stapelweise hinzufügen',
    batchNewCodeQty: 'Bitte geben Sie die Anzahl der neuen Autorisierungscodes ein',
    addAuthCodeConfirm: 'Sind Sie sicher, dass Sie einen WeChat-Autorisierungscode hinzufügen möchten?',
    exportUnusedAuthCode: 'Ungenutzten Autorisierungscode exportieren',
    wxUserInfo: 'WeChat-Benutzerinformationen',
    editUser: 'Benutzer bearbeiten',
    addExistMember: 'Vorhandenes Mitglied hinzufügen',
    addNewMember: 'Neues Mitglied hinzufügen',
    editCorporationIcon: 'Unternehmenssymbol bearbeiten',
    iconPreview: 'Symbolvorschau',
    getRegistrationLink: 'Get Registration Link',
    companyLogo: 'Company Logo',
    bindPhoneWarn: 'Unrelated phone number, please go to the personal center to bind it.',
    bindEmailWarn: 'Unrelated email, please go to the personal center to bind it.',
    mainUsername: 'Main account username',
    mainPwd: 'Main account password',
  },
  /////////////////// 用户管理 /////////////////
  user: {
    addUser: 'Benutzer hinzufügen',
    lastLogin: 'Letzte Anmeldezeit',
    roleName: 'Rollenname',
    editPsw: 'Passwort ändern',
    resetPsw: 'Passwort zurücksetzen',
    resetSuccess: 'Zurücksetzen erfolgreich',
    newPsw: 'Neues Passwort',
    agiainPsw: 'Passwort bestätigen',
    password: 'Passwort',
    childAccoutCount: 'Gesamtzahl der Unterkonten',
    usernameOrname: 'Benutzername/Name',
    backUserManage: 'Zurück zur Benutzerverwaltung',
    platformRole: 'Plattformrolle',
    roleRelationship: 'Rollenbeziehung',
    rightCode: 'Berechtigungscode',
    order: 'Reihenfolge',
  },
  /////////////////// 子账号管理 /////////////////
  subAccout: {
    addSubAccout: 'Unterkonto hinzufügen',
    canAddSubAccoutNum: 'Gesamtzahl der Unterkonten, die hinzugefügt werden können', 
    existSubAccoutNum: 'Anzahl der vorhandenen Unterkonten',
    surplusAccoutNum: 'Verbleibende Anzahl der Unterkonten, die hinzugefügt werden können',
    attention: 'Hinweis: Dieser Vorgang kann nicht wiederhergestellt werden! Bitte gehen Sie mit Vorsicht vor!',
    confirmAccout: 'Geben Sie das Passwort des aktuellen Kontos ein und bestätigen Sie die Löschung des Unterkontos',
    confirmAccoutSubmit: 'Bitte geben Sie das Unterkontopasswort ein, bevor Sie den Vorgang absenden',
    backAccoutMange: 'Zurück zur Unterkontoverwaltung',
    disbuteDevice: 'Verteilungsgerät',
    disbuteDeviceGroup: 'Verteilungsgerätegruppe',
    confirmEmptyItem: 'Möchten Sie wirklich alle Optionen löschen?',
    deviceIdQuery: 'Geben Sie die Geräte-ID ein, um Informationen abzufragen',
    deviceNameQuery: 'Geben Sie den Gerätenamen ein, um Informationen abzufragen',
  },
  /////////////////// 经销商管理 /////////////////
  dealer: {
    dealerManage: 'Händlerverwaltung',
  },

  ///////////////////   系统   /////////////////
  /////////////////// 登录日志 /////////////////
  loginLog: {
    loginLog: 'Anmeldeprotokoll',
    selectUser: 'Bitte wählen Sie einen Benutzer',
    loginIp: 'Anmelde-IP',
    loginMode: 'Anmeldemethode',
    loginTime: 'Anmeldezeit',
  },
  /////////////////// 更新日志 /////////////////
  uploadLog: {
    uploadLog: "Update-Protokoll",
    addUploadLog: 'Aktualisierungsprotokoll hinzufügen',
    title: 'Titel',
    logContent: 'Protokollinhalt'
  },
  /////////////////// 设备日志 /////////////////
  deviceLog: {
    deviceLog: 'Geräteprotokolle',
    platform: 'Plattform',
    aliPlatform: 'Ali-Plattform',
    aliWeChat: 'Ali Wechat',
    tuyaPlatform: 'TU YA',
    ali: 'Ali-Cloud',
    yiYuan: 'Yi Yuan',
    oerationContent: 'Operationsinhalt',
    oerationTime: 'Operationszeit',
    oerationPerson: 'Operationspersonal',
    logType: 'Protokolltyp',
    dataPoint: 'Datenpunktname',
    setValue: 'Einstellungswert:',
    sendOrder: 'Befehl erlassen',
    confirmSendOrder: 'Möchten Sie diesen Befehl wirklich erlassen?',
    dataName: 'Datenname',
    dataList: 'Datenliste',
    selectDataPointAnaly: 'Datenpunkte zur Analyse auswählen',
    selectDataPointAnalyAndChart: 'Datenpunkte zur Analyse auswählen und Datendiagramme anzeigen',
    unitNum: 'Gerätenummer',
    analyze: 'Analyse',
  },

  ///////////////////   个人中心   /////////////////
  personCenter: {
    editName: 'Namen bearbeiten',
    editPhone: 'Kontaktdaten bearbeiten',
    editEmail: 'E-Mail bearbeiten',
    editAddress: 'Adresse bearbeiten',
    oldPhone: 'Already bound phone number',
    newPhone: 'Newly bound phone number',
    oldEmail: 'Email already bound',
    newEmail: 'New email binding',
  },

  ///////////////////   首页   /////////////////
  front: {
    dataAnalyze: 'Datenanalyse',
    noData: 'Keine Daten',
    productList: 'Produktliste',
    productTotal: 'Gesamtzahl der Produkte',
    deviceTotal: 'Gesamtzahl der Geräte',
    onlineDeviceTotal: 'Gesamtzahl der Online-Geräte',
    faultTotal: 'Gesamtzahl der Geräteausfälle',
    activeData: 'Aktive Daten',
    onlineData: 'Online-Daten',
    scanTotal: 'Anzahl der Geräte durch Scan-Hinzufügen'
  },

  ///////////////////   设备   /////////////////
  ///////////////////   设备管理   /////////////////
  deviceManage: {
    deviceList: 'Geräteliste',
    lastOnlineTime: 'Letzte Online-Zeit',
    activeTime: 'Activation time',
    netCfgTime: 'Configure network time',
    LonLat: 'Längen- und Breitengrad',
    ip: 'IP-Adresse',
    dataPointList: 'Datenpunktliste',
    childAccoutList: 'Unterkontoliste',
    runStatus: 'Betriebsstatus',
    paramGroup: 'Parametersatz',
    dataAnaly: 'Datenanalyse',
    dataAnalyNew: 'Datenanalyse (NEU)',
    backDeviceList: 'Zurück zur Geräteliste',
    category: 'Kategorie',
    region: 'Region',
    iotCard: 'IoT-Kartennummer',
    image: 'Symbol',
    multipleUnit: 'Gerätegruppe',
    unitNum: 'Multiple unit quantity',
    value: 'Wert',
    valueAndUnit: 'Wert/Einheit',
    dataSpecs: 'Funktionspunktattribut',
    funcPoint: 'Funktionspunkt',
    currentStatus: 'Aktueller Status',
    setValue: 'Einstellungswert',
    dataDescirpt: 'Datenpunktbeschreibung',
    showIdentifier: 'Identifikator anzeigen',
    show: 'Anzeigen',
    hide: 'Ausblenden',
    elecAnaly: 'Stromanalyse',
    powerConsump: 'Stromverbrauch (kW/h)',
    on: 'Ein',
    off: 'Aus',
    sortBydrag: 'Sortieren durch Drag-and-Drop',
    bigDataLineChart: 'Liniendiagramm mit Big-Data-Volumen',
    saveAsImage: 'Als Bild speichern',
    restore: 'Wiederherstellen',
    dataZoom: 'Zoom',
    dataZoomBack: 'Zoom zurücksetzen',
    fullScreen: 'Vollbild',
    timeRangeNoEmpty: 'Der Zeitbereich darf nicht leer sein',
    timeLimitWarn: 'Die Frist für die Datenabfrage sind die letzten 30 Tage und die Start- und Endzeitspanne darf  7 Tage nicht überschreiten',
    exceeds7days: 'Der Start- und Endzeitraum überschreitet 7 Tage',
    exceeds30days: 'Die Datenabfragezeit ist nur auf die letzten 30 Tage beschränkt',
    thisTimeNoData: 'In diesem Zeitraum sind keine Daten vorhanden',
    timeranularity: 'Zeitgranularität',
    queryTime: 'Abfragezeit',
    all: 'Alle',
    historicalData: 'Historische Daten',
    exportAllData: 'Alle Datenpunkte exportieren',
    region: 'Region',
    address: 'Detaillierte Adresse',
    batchSetLabel: 'Geräte können durch stapelweise Auswahl und Eingabe von Geräteadresse eingestellt werden. ',
    batchSetBtn: 'Legen Sie Gerätestandorte stapelweise fest',
    confirmUpdateAddress: 'Bestätigen, um die Geräteadresse zu aktualisieren?',
    selectedDevice: 'Gerät ausgewählt',
    flowExpiry: 'SIM Expiry Date',
    wxDisabledFault: 'Wechat Fault Display',
    devShadow: 'Device Shadow',
    LastReportedTime: 'Last Reported Time',
    IsActive: 'Is Acitve',
    baseInfo: 'Basic Infomation',
    exportPgData: 'Export Parameter Group Data',
    advancedQuery: 'Advanced Query',
    firmwareUpgrade: 'Firmware Upgrade',
    firmwareKey: 'Firmware Key',
    upgradeStatus: 'Upgrade Status',
    currentVersion: 'Current Version',
    version: 'Version',
    upgradingDesc: 'Upgrading Desc',
    devType: 'Device Type',
    type: 'Channel number',
    typeDesc: 'Module description',
    lastUpgradeTime: 'Last Upgrade Time',
    firmwareDeployTime: 'Firmware Deploy Time',
    remind: 'Reason for non upgradability',
    refreshFirmwareInfo: 'Refresh Firmware Upgrade Info',
    noUpgrade: 'No upgrade',
    deviceReady: 'Device Ready',
    upgrading: 'Upgrading',
    upgradeCompleted: 'Upgrade Completed',
    upgradeException: 'Upgrade Exception',
    upgradeTimeout: 'Upgrade Timeout',
    normalDevice: 'Normal Device',
    nonSurvivalDevices: 'Non Survival Device',
  },
  ///////////////////   产品管理   /////////////////
  productManage: {
    productList: 'Produktliste',
    addProduct: 'Produkt hinzufügen',
    productType: 'Produkttyp',
    backProductList: 'Zurück zur Produktliste',
    dataDic: 'Datenwörterbuch',
    deviceDataConfig: 'Datenpunkte der Geräteliste konfigurieren',
    paramGroupConfig: 'Parametergruppenkonfiguration',
    belongProject: 'zugehöriges Projekt',
    importDictConfig: 'Konfigurationsdatei des Datenwörterbuchs importieren',
    detailInfo: 'Detaillierte Informationen',
    sn: 'Seriennummer',
    identifier: 'Identifikator',
    identifierName: 'Name vom Identifikator',
    readWriteType: 'Lese-/Schreibtyp',
    paramGroupType: 'Parameter Group Type',
    dataType: 'Datentyp',
    clickUpload: 'Datei hochladen',
    fileDrop: 'Datei hineinziehen oder',
    addParamGroup: 'Parametergruppenkonfiguration hinzufügen',
    regularcollectData: 'Regelmäßige Datenerfassung',
    addregularcollectData: 'Regelmäßige Datenerfassung hinzufügen',
    regularCollection: 'Regelmäßige Erfassung',
    regularCollectionDisabled: 'Die Funktion der regelmäßigen Datenerfassung wurde deaktiviert',
    regularCollectionEnabled: 'Die Funktion der regelmäßigen Datenerfassung wurde aktiviert',
    allowUnauthBind: 'Mobiles Terminal ermöglicht unbefugten Benutzern die Bindung',
    cancelAllowUnauthBind: 'Unbefugten Benutzern ist die Bindung an das mobile Endgerät nicht möglich',
    allowUnauthBindConfirm: 'Sind Sie sicher, dass Sie nicht autorisierten Benutzern die Bindung erlauben möchten?',
    cancelAllowUnauthBindConfirm: 'Bestätigen, um die Bindung nicht autorisierter Benutzer abzubrechen?',
    importRemarks: 'Notizen importieren',
    productRefreshSuccess: 'Synchronisierung der Geräteinformationen erfolgreich',
    importDiyFault: 'Import custom Fault Configuration',
    autoRelate: 'Auto Relate',
    faultTemplateDownload: 'Download Fault Template',
    faultConfigExport: 'Export Fault Configuration',
    wxDisabledFaultWarn: 'This operation will cover all devices under the product, continue?',
    devWxFaultPush: 'Device WeChat fault push'
  },
  ///////////////////   小程序配置   /////////////////
  miniProgram: {
    miniProgram: 'Mini-Programmkonfiguration',
    dataPointMap: 'Datenpunktzuordnung',
    timingControl: 'Regelmäßige Datenpunkte',
    factoryParam: 'Werksparameter',
    addFuncPoint: 'Funktionspunkte hinzufügen',
    publicDataIdentify: 'Öffentliche Datenpunktidentifikation',
    dataPointName: 'Datenpunktname',
    dataPoint: 'Datenpunkt',
    modelValue: 'Moduswert',
    switch: 'Schalter',
    model: 'Modus',
    heatTemp: 'Heiztemperatur',
    coolTemp: 'Kühltemperatur',
    currentTemp: 'Aktuelle Temperatur',
  },
  ///////////////////   定时数据点   /////////////////
  timeControl:{
    addTimePoint: 'Periodischen Datenpunkt hinzufügen',
    timeOpenPointHour: 'Regelmäßige Datenpunkte einschalten (Stunde)',
    timeOpenPointMin: 'Regelmäßige Datenpunkte einschalten (Minuten)',
    timeClosePointHour: 'Regelmäßige Datenpunkte ausschalten (Stunde)',
    timeClosePointMin: 'Regelmäßige Datenpunkte ausschalten (Minuten)',
    timeOpenHour: 'Zeitgesteuertes Einschalten (Stunde)',
    timeOpenMin: 'Zeitgesteuertes Einschalten (Minuten)',
    timeCloseHour: 'Zeitgesteuertes Ausschalten (Stunde)',
    timeCloseMin: 'Zeitgesteuertes Ausschalten (Minuten)',
  },
  ///////////////////   工厂参数   /////////////////
  factoryParam: {
    addFactortParam: 'Werksparameter hinzufügen',
  },
  ///////////////////   故障管理   /////////////////
  faultMange: {
    faultMange: 'Fehler-Verwaltung',
    faultList: 'Fehlerliste',
    productIdOrPointName: 'Produkt-ID/Datenpunktname',
    faultPoint: 'Fehlerdatenpunkt',
    faultCode: 'Fehlercode',
    faultNum: 'Fehlerzahl',
    faultTime: 'Fehlerzeit',
    diyFaultPoint: 'Benutzerdefinierte Fehlerdatenpunkte',
    addDiyFaultPoint: 'Benutzerdefinierte Fehlerdatenpunkte hinzufügen',
    faultName: 'Fehlername',
    faultValue: 'Fehlerwert',
    faultStatus: 'Fault Status',
    chooseRemake: 'Hinweis: Klicken Sie auf die aktuelle Zeile, um den Datenpunkt auszuwählen!',
    completeFaultInfo: 'Fehlerwertinformationen vervollständigen',
    configDeviceListDataPoint: 'Datenpunkte der Geräteliste konfigurieren',
    subFault: 'Gerätefehlerbenachrichtigung aktivieren',
    subSuccess: 'Benachrichtigung aktiviert',
    subFail: 'Aktivierung der Benachrichtigung fehlgeschlagen',
    unSubSuccess: 'Benachrichtigung deaktiviert',
    unSubFailt: 'Deaktivierung der Benachrichtigung fehlgeschlagen',
    openSub: 'Benachrichtigung aktivieren',
    closeSub: 'Benachrichtigung deaktivieren',
    notEliminated: 'Not Eliminated',
    eliminated: 'Eliminated'
  },
  ///////////////////   工程管理   /////////////////
  engineerManage: {
    engineerList: 'Projekt-Managementliste',
    addEngineer: 'Projekt hinzufügen',
    notStart: 'Nicht gestartet',
    underway: 'In Betrieb',
    finish: 'Beendet',
    engineerName: 'Projekt-Name',
    engineerTimeRange: 'Start- und Endzeit des Projekts',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    enginnerAddress: 'Projekt-Adresse',
    status: 'Projekt-Status',
    creator: 'Ersteller',
    backEngineerList: 'Zurück zur Projekt-Liste',
    deviceOperate: 'Geräteoperation',
    addDevice: 'Gerät hinzufügen',
    ownProduct: 'zugehöriges Produkt',

  },
  ///////////////////   设备归属   /////////////////
  deviceOwnership: {
    deviceOwnership: 'Gerätebesitz',
  },
  ///////////////////   设备审核   /////////////////
  deviceAudit: {
    deviceAudit: 'Device Audit',
    imei: 'IMEI',
    customerName: 'Customer Name',
    customerPhone: 'Customer Phone',
    installLocationTotalName: 'Install Region',
    installAddress: 'Install Address',
    installTime: 'Install Time',
    auditDetail: 'Audit Detail',
    inProgress: 'In progress',
    approved: 'Approved',
    rejected: 'Rejected',
    submitted: 'Submitted',
    approve: 'Approve',
    reject: 'Reject',
    audit: 'Audit',
    opinion: 'Audit Comments',
    dealerName: 'Dealer',
    installImages: 'Install Images',
    auditRecords: 'Audit Records',
  },
  ///////////////////   云开发   /////////////////
  ///////////////////   客户管理   /////////////////
  clientManagement: {
    clientManagement: 'Kundenmanagement',
    clientId: 'Kunden-ID',
    corporationName: 'Name des Kundenunternehmens',
    clientSecret: 'Kundenschlüssel',
    confirmReset: 'Sind Sie sicher, dass Sie den Schlüssel zurücksetzen möchten?',
    serviceOverview: 'Serviceübersicht',
  },
  ///////////////////   API管理   /////////////////
  apiManagement: {
    apiManagement: 'API-Verwaltung',
    apiName: 'API-Name',
    apiAddr: 'API-Zugriffsadresse',
    apiDocUrl: 'API-Dokumentadresse',
    timeRange: 'Zeitbereich',
    totalCalls: 'Gesamtanzahl der Abrufe',
    failedCalls: 'Anzahl fehlgeschlagener Anrufe',
    failureRate: 'Fehlerrate',
    totalMsg: 'Gesamtzahl der erhaltenen Nachrichten',
    apiRequest: 'API-Anfragestatistiken',
    apiDetail: 'Details zur API-Anfrage',
    deviceStatistics: 'Statistiken zu Gerätenachrichten',
  },
  ///////////////////   流量卡   /////////////////
  simCard: {
    simCard: 'SIM',
    companyManage: 'Company Management',
    addCompany: 'Add Company',
    appKey: 'App Key',
    secret: 'Secret',
    companyId: 'Company ID',
    companyName: 'Company Name',
    billingGroup: 'Billing Group',
    cardList: 'Card List',
    bgId: 'Billing Group ID',
    billingCode: 'Billing Group Code',
    carrier: 'Carrier',
    setMeal: 'Set Meal',
    cardType: 'Card Type',
    productCode: 'Product Code',
    isFlowShare: 'Flow Share',
    isShare: 'Shared',
    notShare: 'Non-shared',
    useFlow: 'Used Flow（MB）',
    sumFlow: 'Total Flow（MB）',
    residualFlow: 'Residual Flow（MB）',
    billingUseFlow: 'Flow Pool Used Flow（MB）',
    billingSumFlow: 'Flow Pool Total Flow（MB）',
    billingResidualFlow: 'Flow Pool Residual Flow（MB）',
    serviceCycle: 'Service Cycle',
    status: 'SIM Status',
    pauseReason: 'Pause Reason',
    allotDate: 'Allot Date',
    activeStatus: 'Active Status',
    openDate: 'Open Date',
    activeDate: 'Active Date',
    expiryDate: 'Expiry Date',
    monthFlow: 'Month Used Flow（MB）',
    flow: 'Billing Cycle Used Flow（MB）',
    gprsStatus: 'GPRS Status',
    realnameStatus: 'Real name Authentication',
    cardSum: 'Card Sums',
    notActivated: 'Not activated',
    actived: 'Activated',
    unknow: 'Unknown',
    other: 'Other',
    stopped: 'Stopped',
    preCancel: 'Pre-cancellation',
    canceled: 'Canceled',
    pendActive: 'Pending activation',
    active: 'Active',
    activeTesting: 'Active in testing phase',
    stoppedTesting: 'Stopped in testing phase',
    suspended: 'Suspended (number retained)',
  },
  ///////////////////   故障分析   /////////////////
  faultAnalysis: {
    faultAnalysis: 'Fault Analysis',
    analysis: 'Statistics and Push Failure',
    productDevErrorNum: 'Products and Devices Failure',
    corporationTopError: 'Reported Faults in Company',
    productError: 'Reported Faults in Company Products',
    deviceError: 'Reported Faults in Company Devices',
    faultDeviceNum: 'Faulty devices Number',
    faultProductNum: 'Faulty Products Number',
    faultNum: 'Number of Faults',
  },
  ///////////////////   路由名称   /////////////////
  routeName: {
    login: 'Anmelde-Seite',
    homePage: 'Verwaltungs-Homepage',
    updateLogs: 'Protokolle aktualisieren',
    personalCenter: 'Persönliches Zentrum',
    loginLogs: 'Anmeldeprotokolle',
    deviceLogs: 'Geräteprotokolle',
    system: 'System-Homepage',
    permission: 'Berechtigungs-Homepage',
    corporationManage: 'Unternehmensverwaltung',
    subAccout: 'Unterkontoverwaltung',
    subAccoutDetail: 'Unterkontodetails',
    dealer: 'Händler',
    dealerDetail: 'Händlerdetails',
    afterSales: 'After Sales-Homepage',
    device: 'Geräte-Homepage',
    deviceList: 'Geräteliste',
    deviceDetail: 'Gerätedetails',
    productList: 'Produktliste',
    productiDetail: 'Produktdetails',
    miniProgramConfig: 'Mini-Programmkonfiguration',
    errorManage: 'Fehler-Verwaltung',
    engineerManage: 'Projekte verwalten',
    engineerDetail: 'Projektdetails',
    map: 'Geräte-Karte',
    ownership: 'Gerätebesitz',
    btIotPlatform: 'IoT',
  }
}

export default de;